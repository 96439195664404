import env from '@polleverywhere/core/lib/env'
import '../../lib/globals/env'

/**
 * Setup the nginx proxy for sub-apps that require it
 * In production the ALB provides the /proxy functionality
 */
export function setupApiProxy () {
  if (env.get('proxy_xdomain_api')) {
    env.set('nginx_proxy_path', '/proxy')
    env.set('graphql_url', '/proxy/graphql')
  }
}
