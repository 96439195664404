import '../lib/globals/babel'
import '../lib/chunk_load_handler'

import loadPolyfills from '../lib/polyfills'
import { addInstallPromptListener } from './util/pwa'
import { setupApiProxy } from './util/setupApiProxy'
import { uninstallServiceWorker } from './util/uninstallServiceWorker'

import '../lib/init.scss'

async function bootApp () {
  const opts = {
    role: 'participant',
    appName: 'Poll Everywhere Participant',
    allowAnalyticsTracking: 'consent',
    typekit: false,
    outOfBandAuth: window.Capacitor?.isNative,
    serviceWorker: false
  }

  setupApiProxy()
  uninstallServiceWorker()

  const startAppLoader = (await import(/* webpackChunkName: "start-app" */ './util/startApp'))
  addInstallPromptListener()
  const appendTo = document.getElementById('main-content') ? '#main-content' : 'body'
  startAppLoader.startApp(opts, appendTo)
}

loadPolyfills().then(async () => {
  if (navigator.onLine) {
    bootApp()
  } else {
    const offlineMessage = (await import(/* webpackChunkName: 'offline-message' */ './util/offlineMessage')).offlineMessage
    offlineMessage('Poll Everywhere Participant')
  }
})
