export function uninstallServiceWorker () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(r => {
        console.log('Unregistering service worker')
        r.unregister()
      })
    })
  }
}
