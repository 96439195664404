export default function loadPolyfills () {
  // This can come in async - so don't include in promise
  import(/* webpackChunkName: "pf-focus-visible" */ 'focus-visible') // eslint-disable-line no-unused-expressions

  const polyfills = []

  const needsCustomElementsPolyfills =
    !('isConnected' in Node.prototype) ||
    !window.customElements ||
    !Element.prototype.toggleAttribute

  if (needsCustomElementsPolyfills) {
    polyfills.push(import(/* webpackChunkName: "pf-custom-elements" */ './custom-elements'))
  }

  return Promise.all(polyfills)
}
