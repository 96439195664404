// When web presenter redirects to singularity in the mac app WebView
// it occassionally fails loading resources and a unhandled rejection
// error is triggered inside of Webpacks async loading system.
// The errors look like "Loading chunk 0 failed". This hack
// reloads the page immediately if it detects this happens and the
// entire app is "fixed". This should go away when the WebView container
// in the MacApp is replaced with the newer WKWebView or Electron.
window.addEventListener('error', (event) => {
  console.log(`unhandled error: ${event.message}`)
  const chunkFailedMessage = /Loading.*chunk [\d]+ failed/

  if (chunkFailedMessage.test(event.message)) {
    console.log('reloading window')
    // setTimeout(() => {
    //   window.location.reload()
    // }, 3000)
  }
})

window.addEventListener('unhandledrejection', function (event) {
  console.log(`unhandled rejection: ${event.reason}`)
  const chunkFailedMessage = /Loading.*chunk [\d]+ failed/

  if (chunkFailedMessage.test(event.reason)) {
    console.log('reloading window')
    // setTimeout(() => {
    //   window.location.reload()
    // }, 3000)
  }
})
